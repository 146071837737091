<template lang='pug'>
	.faq.constrainer
		.first-content
			Crumbs(slug='/frequently-asked-question')
			h1.title Frequently asked questions.
			.dashes
				.dash(v-for='n in 5')
		.questions
			.question(v-for='q, i in questions' :class='{ active: q.active }')
				.q.clickable(@click='viewQuestion(i)')
					.text.fs {{ q.fields.question }}
					.sign
						.one(:class='{ active: q.active }')
						.two
				.a.gutter(:class='{ active: q.active, animated: q.animated }')
					p.fs(v-for='answer in q.fields.answer') {{ answer }}
		Contact
		//- .bluecarrental-banner
		//- 	BlueCarRentalBanner(
		//- 		:image="{ url: require('@/assets/bluecarrental/image5.jpg') }"
		//- 	)
		.section-pad
			BlueCarRentalBanner(
				:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
				text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
				text-align="left"
			)
</template>

<script>
// @ is an alias to /src
export default {
	name: 'FAQ',
	metaInfo: {
		title: 'Safe.is - Frequently asked questions.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/faq/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/home.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Frequently asked questions.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Check out frequently asked questions about driving in Iceland and about keeping safe while driving in Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Check out frequently asked questions about driving in Iceland and about keeping safe while driving in Iceland.'
			}
		]
	},
	data: function () {
		return {
			questions: []
		};
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	created: function () {
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/faq').then((result) => {
			this.questions = result.data;
		});
	},
	methods: {
		viewQuestion: function (index) {
			for (let i = 0; i < this.questions.length; i++) {
				if (i === index) {
					this.questions[i].active = !this.questions[i].active;
					if (this.questions[i].active) {
						setTimeout(() => {
							this.questions[i].animated = true;
						}, 100);
					} else {
						this.questions[i].animated = true;
					}
				} else {
					this.questions[i].active = false;
					this.questions[i].animated = false;
				}
			}
		}
	}
};
</script>

<style lang='less'>
.faq {
	margin-top: 20px;
	margin-bottom: 20px;
	.questions {
		.question {
			transition: .3s;
			margin-bottom: 47px;
			background-color: #FAFAFA;
			box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, .15);
			&:hover {
				transform: scale(1.01);
				box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .15);
			}
			&.active {
				background-color: rgba(0,0,0,0);
			}
			.q {
				padding-top: 21px;
				padding-bottom: 21px;
				padding-left: 25px;
				padding-right: 25px;
				@media screen and (max-width: 1220px) {
					padding-top: 25px;
					padding-bottom: 25px;
				}
				display: grid;
				grid-template-columns: 1fr 11px;
				grid-column-gap: 20px;
				.text {
					text-align: left;
				}
				.sign {
					top: 50%;
					transform: translate3d(0px, -50%, 0px);
					position: relative;
					height: 11px;
					.one {
						position: absolute;
						left: 50%;
						transition: .3s;
						transform: translate3d(-50%, 0px, 0px) rotateX(0deg);
						&.active {
							transform: translate3d(-50%, 0px, 0px) rotateX(90deg);
						}
						height: 11px;
						width: 2px;
						background-color: #F9DB49;
					}
					.two {
						left: 1px;
						position: absolute;
						top: 50%;
						transform: translate3d(0px, -50%, 0px);
						width: 11px;
						height: 2px;
						background-color: #F9DB49;
					}
				}
			}
			.a {
				pointer-events: none;
				transition: .3s;
				display: none;
				opacity: 0;
				padding-bottom: 20px;
				&.active {
					transform: rotate3d(1, 0, 0, 90deg);
					display: block;
				}
				&.animated {
					opacity: 1;
					transform: rotate3d(1, 0, 0, 0deg);
				}
				margin-top: 18px;
				text-align: left;
				color: #4D4D4D;
				font-size: 15px;
				letter-spacing: 0.6px;
				line-height: 22px;
			}
		}
	}
	.first-content {
		.dashes {
			margin-top: 48px;
			margin-bottom: 64px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			width: 60px;
			.dash {
				width: 8px;
				height: 2px;
				background-color: #F9DB49;
			}
		}
	}
	.bluecarrental-banner {
		margin-top: 96px;
		margin-bottom: 96px;
	}
}
</style>
